.grid-table-align-start .grid-table-cell {
  justify-content: flex-start;
}
.grid-table-column-title:hover .sort-svg {
  opacity: 100;
}

.grid-table-column-title-fixed {
  position: sticky;
  top: 0;
  z-index: 1;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
main,
#root {
  /* font-family: 'Segoe'; */
  height: 100%;
}

body {
  background: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.overflow-y-scroll::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-scroll::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.overflow-y-scroll::-webkit-scrollbar-thumb {
  background-color: #006bb2;
  border-radius: 100px;
}

/* #map {
  filter: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="g"><feColorMatrix type="matrix" values="0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0 0 0 1 0"/></filter></svg>#g');
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);
} */

.pac-container {
  /* width: 500px !important; */
  margin-left: -20px;
}

.pac-item {
  display: flex;
  flex-direction: column;
  white-space: normal;
  word-break: break-word;
  line-height: 16px;
  padding: 4px 6px;
}

/* .pac-item span {
  white-space: normal;
  word-break: break-word;
} */

.pac-icon {
  display: none;
}

.custom-clustericon {
  color: #006bb2;
  font-weight: bold;
  font-size: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus-visible {
  outline: none;
}

.custom-input[type='date']::-webkit-inner-spin-button,
.custom-input[type='date']::-webkit-calendar-picker-indicator,
.custom-input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  appearance: none;
}

/* BORDERS */

.primary.focused {
  border-color: #006bb2;
}

.danger.focused {
  border-color: #ee0202;
}

.success.focused {
  border-color: #3cb15b;
}

/* CARETS */

.primary input {
  caret-color: #006bb2;
}

.danger input,
.success input {
  caret-color: black;
}

/* SELECTION */
.danger::selection {
  background-color: #ee0202;
}

.success::selection {
  background-color: #3cb15b;
}
